import React, { useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import styled from "styled-components";

const StyledPhoneInputWrapper = styled.div`
  .country-list .country:hover {
    background-color: grey !important; /* Transparent hover background */
    color: white !important; /* Ensure the text color stays white */
  }

  /* Style for the currently selected country in the dropdown */
  .country-list .country:selected {
    background-color: red !important; /* Red background for the selected item */
    color: white !important; /* Ensure the text color stays white */
  }
`;

const CountryCodeSelect = ({
  phone,
  setCountryCode,
  setPhone,
  countryCode,
}) => {
  useEffect(() => {
    setCountryCode("+1");
  }, []);

  return (
    <StyledPhoneInputWrapper
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
        marginBottom: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "transparent",
          borderRadius: "20px",
          border: "1.5px solid rgba(255, 255, 255, 0.47)",
          padding: "16px 5px",
          minWidth: "100px",
          minHeight: "58.5px",
          color: "white",
        }}
      >
        <div className="w-50">
          <PhoneInput
            country={"us"} // default country
            onChange={(value, country) => {
              // Only keep the country code, e.g., +1, +91
              setCountryCode(`+${country.dialCode}`);
            }}
            inputStyle={{
              display: "none", // hide the input field
            }}
            buttonStyle={{
              backgroundColor: "transparent", // transparent flag button background
              border: "none", // no border on flag dropdown
              color: "white",
              width: "30px",
            }}
            dropdownStyle={{
              backgroundColor: "#2d2d2d", // dark background for the dropdown
              color: "white", // white text in the dropdown
            }}
          />
        </div>

        <span style={{ color: "white"}}>
          {countryCode}
        </span>
      </div>
      <input
        type="text"
        style={{
          minHeight: "58.5px",
          padding: "16px 5px",
          border: "1.5px solid rgba(255, 255, 255, 0.47)",
          borderRadius: "20px",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          background: "transparent",
          color: "rgba(255, 255, 255, 1)",
          outline: "none",
          textAlign: "center",
        }}
        className="outlined-input phone-field mx-1"
        value={phone}
        onChange={(e) => {
          if (e.target.value.length > 10 || isNaN(e.target.value)) {
            return;
          }
          setPhone(e.target.value);
        }}
      />
    </StyledPhoneInputWrapper>
  );
};

export default CountryCodeSelect;
